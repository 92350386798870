import { format } from 'date-fns';
import { countryCodes } from './iocCountryCodes';
import { parse } from 'date-fns';

export const getOlympicDays = (start: Date, end: Date) => {
  for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const formatDate = (date: Date) => {
  try {
    return `${format(new Date(date), 'EEE dd MMM, h.mm a')}`;
  } catch (e) {
    return 'TBD';
  }
};

export const formatStringDate = (date: string) => {
  const a = parse(date, 'd-MMM-yy H:mm', new Date(), {});
  return formatDate(a);
};

// Show the  events for the same day as the filter
export const selecteFilteredDates = (dateEvent: Date, filterDate: Date) => {
  const a = new Date(dateEvent.toDateString());
  const b = new Date(filterDate.toDateString());
  return a.valueOf() === b.valueOf();
};

// Check if the event date is older than 6 hours from now.
export const isPastDate = (date: Date) => {
  const eventDate = new Date(date);
  const sixHoursFromNow = new Date();
  sixHoursFromNow.setHours(sixHoursFromNow.getHours() - 6);
  return eventDate.valueOf() < sixHoursFromNow.valueOf();
};

// Check if the filter date is older than 6 hours from now.
export const isFilterPastDate = (date: Date) => {
  const eventDate = new Date(date);
  const sixHoursFromNow = new Date();
  sixHoursFromNow.setHours(sixHoursFromNow.getHours() - 6);
  return eventDate.valueOf() < sixHoursFromNow.valueOf();
};

// Find the countryName based on the ioc Country Code
export const getIOCCountryName = (iocCode: string) => {
  return countryCodes.find(cc => cc.code.trim().toLowerCase() === iocCode.trim().toLocaleLowerCase());
};
