import axios from 'axios';
import { JSONConfigService, NavigationService, OlympicScheduleService, T_EPG_Channel } from '@sky-tv-group/shared';
import {
  EPG_CHANNELS_API,
  NAVIGATION_URL,
  OLYMPIC_SCHEDULE_SERVICE_URL,
  OLYMPIC_MEDAL_TABLE_URL,
  JSON_CONFIG_URL,
} from './config';
import { MedalRecord } from './types';

const agentNavigation = axios.create({ baseURL: NAVIGATION_URL });
export let navService = new NavigationService(agentNavigation);

export const agentJSON = axios.create({ baseURL: JSON_CONFIG_URL });
export let jsonConfigService = new JSONConfigService(agentJSON);

const agentOlympicSchedule = axios.create({ baseURL: OLYMPIC_SCHEDULE_SERVICE_URL });
export let olympicService = new OlympicScheduleService(agentOlympicSchedule);

export const getChannels = async () => {
  const { data } = await axios.get<T_EPG_Channel[]>(EPG_CHANNELS_API);
  return data;
};

export const getMedals = async () => {
  try {
    const { data } = await axios.get<MedalRecord[]>(OLYMPIC_MEDAL_TABLE_URL);
    return data;
  } catch (error) {
    return [];
  }
};
