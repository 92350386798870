import { AxiosInstance } from 'axios';

export class OlympicScheduleService {
  private olympicAgent: AxiosInstance;
  constructor(olympicAgent: AxiosInstance) {
    this.olympicAgent = olympicAgent;
  }
  public getOlympicSchedule = async () => {
    try {
      const response = await this.olympicAgent.get(`/olympic-schedule`);
      return response.data;
    } catch (err) {
      let errorObj = err?.response?.data;
      return errorObj?.code;
    }
  };
}
