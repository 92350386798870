import React from 'react';
import { Filters } from './filters';
import { useJSONConfig } from '@sky-tv-group/shared';
import { jsonConfigService } from '../../services';

interface ScheduleHeaderProps {
  isFullView?: boolean;
}

const ScheduleHeader = ({ isFullView = false }: ScheduleHeaderProps) => {
  let config = useJSONConfig(jsonConfigService);
  const title = config?.kiwiathletes?.schedule?.title ?? 'Kiwi Schedule';
  return (
    <div className="flex flex-col-reverse md:flex-row justify-between items-center p-4 md:px-8">
      <div className="flex flex-col w-full">
        <h1 className="sky-h4-black md:sky-h3-black pb-2">{title}</h1>
        {isFullView ? (
          <Filters />
        ) : (
          <div className="md:sky-h7">View the full table to filter by Date, Discipline or Athlete Name </div>
        )}
      </div>
      <div className="flex flex-col items-center max-w-xs">
        <img src="https://static.sky.co.nz/sky/olympics/img/kiwiathletes.png" className="pl-2" alt="Kiwi Athletes" />
      </div>
    </div>
  );
};

export { ScheduleHeader };
