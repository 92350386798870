const OLYMPIC_SCHEDULE_SERVICE_URL = process.env.REACT_APP_ENDPOINT!;
const OLYMPIC_HOME_URL = process.env.REACT_APP_OLYMPIC_HOME_URL!;
const NAVIGATION_URL = process.env.REACT_APP_LINKS_ENDPOINT!;
const EPG_CHANNELS_API = process.env.REACT_APP_EPG_CHANNELS_ENDPOINT!;
const OLYMPIC_MEDAL_TABLE_URL = process.env.REACT_APP_OLYMPIC_MEDAL_TABLE_URL!;
const OLYMPIC_MEDAL_IOC_ENABLED = process.env.REACT_APP_OLYMPIC_MEDAL_IOC_ENABLED!;
const JSON_CONFIG_URL = process.env.REACT_APP_JSON_CONFIG_URL!;
const IS_IOC_ENABLED = OLYMPIC_MEDAL_IOC_ENABLED === '1';

export {
  OLYMPIC_SCHEDULE_SERVICE_URL,
  OLYMPIC_HOME_URL,
  NAVIGATION_URL,
  EPG_CHANNELS_API,
  OLYMPIC_MEDAL_TABLE_URL,
  JSON_CONFIG_URL,
  IS_IOC_ENABLED,
};
