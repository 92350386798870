import React from 'react';
import { useJSONConfig } from '@sky-tv-group/shared';
import { jsonConfigService } from '../../services';

const MedalHeader = () => {
  let config = useJSONConfig(jsonConfigService);
  const title = config?.kiwiathletes?.medal?.title ?? 'Medal Table';
  return (
    <div className="flex flex-col-reverse md:flex-row justify-between items-center p-4 md:px-16 lg:px-20">
      <div className="flex flex-col w-full">
        <h1 className="sky-h4-black md:sky-h3-black py-2">{title}</h1>
      </div>
      <div className="flex flex-col items-center max-w-xs">
        <img src="https://static.sky.co.nz/sky/olympics/img/kiwiathletes.png" className="pl-2" alt="Kiwi Athletes" />
      </div>
    </div>
  );
};

export { MedalHeader };
