import { OlympicScheduleService } from '@sky-tv-group/shared';

function useOlympicSchedule(olympicService: OlympicScheduleService) {
  const getScheduleData = async () => {
    const response = await olympicService.getOlympicSchedule();
    return response;
  };

  const getTableHeader = async () => {};

  const getData = async () => {};

  return {
    getScheduleData,
    getTableHeader,
    getData,
  };
}

export { useOlympicSchedule };
