import React from 'react';
import { IEBanner } from '@sky-tv-group/components';
import { routes } from './routes';
import { Route, Switch } from 'react-router-dom';

const App = () => {
  return (
    <>
      <IEBanner>
        <Switch>
          <Route path="/">
            {routes.map(({ path, exact, component }) => (
              <Route key={path} exact={exact} path={path} component={component} />
            ))}
          </Route>
        </Switch>
      </IEBanner>
    </>
  );
};

export { App };
