import { Loader } from '@sky-tv-group/components';
import React, { useEffect, useState } from 'react';
import { MedalTable } from '../components/medal-table/medal-table';
import { MedalHeader } from '../components/medal-table/header';
import { getMedals } from '../services';
import { useStore } from '../store';
import { OLYMPIC_HOME_URL } from '../config';

const MedalHome = () => {
  const { setMedals } = useStore(s => ({
    setMedals: s.setMedals,
  }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIt = async () => {
      let medalsResponse = await getMedals();
      setMedals(medalsResponse);
      setLoading(false);
    };
    fetchIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className="relative h-128">
        <Loader bgOpacity={false} />
      </div>
    );

  return (
    <div className="bg-white flex" style={{ height: '750px' }}>
      <div className="w-full lg:w-3/4 xl:w-6/10 m-auto px-5 md:px-8">
        <div className="sky-bg-white rounded-lg">
          <MedalHeader />
          <MedalTable isFullView={false} />
          <div className="flex items-center justify-center p-4">
            <a className="sky-button sky-button--primary-light" href={`${OLYMPIC_HOME_URL}/medals`} target="_parent">
              View full table
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MedalHome };
