import React from 'react';
import { caret } from '@sky-tv-group/components';
import { useStore } from '../../store';
import { format } from 'date-fns';
import { isFilterPastDate } from '../../helper/helper';

interface FilterSelectProps {
  update: (value: any) => void;
  filterOptions: any[];
  placeholder: string;
  isDateFilter?: boolean;
}

const FilterSelect = ({ update, filterOptions, placeholder, isDateFilter = false }: FilterSelectProps) => {
  return (
    <div className={`w-full relative md:mx-2 py-1`}>
      <select
        className={`w-full appearance-none px-2 pr-12 h-12 rounded-md bg-white border border-gray-dark`}
        onChange={e => update(e.target.value)}>
        <option value="">{placeholder}</option>
        {filterOptions.map(filter => {
          return <option value={filter}>{isDateFilter ? format(new Date(filter), 'EEE dd MMM') : filter}</option>;
        })}
      </select>
      <div className="w-10 h-12 py-2 absolute text-gray-darker top-0 right-0 pointer-events-none">{caret}</div>
    </div>
  );
};

const Filters = () => {
  const { filterOptions, setDisciplineFilter, setDateFilter, setAthleteFilter } = useStore(s => ({
    filterOptions: s.filterOptions,
    setDisciplineFilter: s.setDisciplineFilter,
    setDateFilter: s.setDateFilter,
    setAthleteFilter: s.setAthleteFilter,
  }));

  let updateDiscipline = (value: string) => {
    setDisciplineFilter(value);
  };

  let updateDate = (value: Date) => {
    setDateFilter(value);
  };

  let updateAthlete = (value: string) => {
    setAthleteFilter(value);
  };

  return (
    <div className="flex flex-row flex-wrap lg:w-3/4 md:flex-no-wrap md:sky-sub ">
      <FilterSelect update={updateDiscipline} filterOptions={filterOptions.discipline} placeholder="All Disciplines" />
      <FilterSelect
        update={updateDate}
        filterOptions={filterOptions.days.filter(day => !isFilterPastDate(day))}
        placeholder="Upcoming"
        isDateFilter={true}
      />
      <FilterSelect update={updateAthlete} filterOptions={filterOptions.atheltes.sort()} placeholder="All Athletes" />
    </div>
  );
};

export { Filters };
