import React from 'react';

const GoBack = () => {
  const back = () => {
    window.history.back();
  };

  return (
    <button onClick={back} className="py-4">
      <div className="flex items-center justify-center flex-no-wrap sky-text-white">
        <img
          src="/images/Icons/chevron_white.svg"
          alt="Go to top"
          className="w-3/4 transform -rotate-90 fill-current"
        />
        <div className="sky-h6-bold px-4" style={{ whiteSpace: 'nowrap' }}>
          Go Back
        </div>
      </div>
    </button>
  );
};

export { GoBack };
