import React, { useEffect, useState } from 'react';
import { selecteFilteredDates, formatStringDate } from '../../helper/helper';
import { useStore } from '../../store';
import { OlympicDataRecord } from '../../types';
import { isMobile, isTablet } from 'react-device-detect';
import { ToTop } from '../toTop';
import { olympicService } from '../../services';
import { Loader } from '@sky-tv-group/components';
import { useOlympicSchedule } from '../../hooks/useOlympicSchedule';

interface ScheduleProps {
  isFullView?: boolean;
}

const Schedule = ({ isFullView = true }: ScheduleProps) => {
  const { getScheduleData } = useOlympicSchedule(olympicService);
  const {
    setOlympicDataRecord,
    setFilterOptions,
    fetchChannels,
    olympicDataRecords,
    disciplineFilter,
    athleteFilter,
    dateFilter,
    channels,
    filterOptions,
  } = useStore(s => ({
    setOlympicDataRecord: s.setOlympicDataRecord,
    setFilterOptions: s.setFilterOptions,
    fetchChannels: s.fetchChannels,
    olympicDataRecords: s.olympicDataRecords,
    disciplineFilter: s.disciplineFilter,
    athleteFilter: s.athleteFilter,
    dateFilter: s.dateFilter,
    channels: s.channels,
    filterOptions: s.filterOptions,
  }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChannels();
    getScheduleData().then(response => {
      if (response) {
        setOlympicDataRecord(response.data);
        setFilterOptions(response.filters);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dataRecords: OlympicDataRecord[] = [];

  if (isFullView && (disciplineFilter || athleteFilter || dateFilter)) {
    dataRecords = olympicDataRecords
      .filter(record => !disciplineFilter || record.discipline.localeCompare(disciplineFilter) === 0)
      .filter(record => !athleteFilter || record.athlete.localeCompare(athleteFilter) === 0)
      .filter(record => !dateFilter || selecteFilteredDates(new Date(record.date), new Date(dateFilter)));
  } else {
    dataRecords = isFullView ? olympicDataRecords : olympicDataRecords.slice(0, isMobile && !isTablet ? 5 : 10);
  }

  if (loading)
    return (
      <div className="relative h-128">
        <Loader bgOpacity={false} />
      </div>
    );

  return (
    <div className="relative flex item-center justify-center md:px-8 pb-8">
      {isMobile && !isTablet ? (
        /* MOBILE VIEW - List */
        <div className="w-full px-8">
          {dataRecords.length > 0 ? (
            <div className="flex flex-col">
              {dataRecords.map(dr => {
                let channel = channels.find(channel => channel.number === dr.channel.padStart(3, '0'));
                return (
                  <div className="flex last:border-0 border-b border-gray-dark ">
                    <div className="flex-col flex-1 sky-h7-bold py-4">
                      <div className="whitespace-nowrap">{formatStringDate(dr.date)}</div>
                      <div>{channel ? `${channel.name} (Ch. ${channel.number})` : dr.channel}</div>
                      <div>{dr.discipline}</div>
                      <div>
                        {dr.event}, {dr.session}
                      </div>
                      <div>{dr.athlete}</div>
                    </div>
                    <div className="flex-col">
                      <img className="w-20" src={channel?.logoThumbnail} alt={channel?.name} />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="sky-h6-reg py-4">No records found</div>
          )}
        </div>
      ) : (
        /* DESKTOP VIEW - Table */
        <div className="relative">
          <table className="w-full table-fixed">
            <thead className="w-full block sky-h6-bold pb-2">
              <tr className="rounded-l-lg rounded-r-lg sky-bg-daylight sky-text-white text-left w-full flex flex-no-wrap items-center justify-center">
                <th className="py-4 px-4" style={{ width: '17%' }}>
                  Date, Time NZT
                </th>
                <th className="text-center px-4" style={{ width: '14%' }}>
                  Channel
                </th>
                <th className="px-4" style={{ width: '15%' }}>
                  Discipline
                </th>
                <th className="px-4" style={{ width: '39%' }}>
                  Event
                </th>
                <th className="px-4" style={{ width: '25%' }}>
                  Athlete/Team
                </th>
              </tr>
            </thead>
            {dataRecords.length > 0 ? (
              <tbody className="sky-h7-bold block">
                {dataRecords.map((dr, index) => {
                  let channel = channels.find(channel => channel.number === dr.channel.padStart(3, '0'));
                  return (
                    <tr key={index} className="text-left flex w-full flex-no-wrap items-center justify-center py-2">
                      <td className="px-4 whitespace-nowrap" style={{ width: '17%' }}>
                        {formatStringDate(dr.date)}
                      </td>
                      {channel ? (
                        <>
                          <td
                            className="px-4 flex flex-row justify-center items-center sky-sub"
                            style={{ width: '14%' }}>
                            <div className="pb-4">{dr.channel.padStart(3, '0')}</div>
                            <img className="w-20" src={channel.logoThumbnail} alt={channel.name} />
                          </td>
                        </>
                      ) : (
                        <td className="p-3 text-center sky-sub" style={{ width: '14%' }}>
                          {dr.channel}
                        </td>
                      )}
                      <td className="px-4" style={{ width: '15%' }}>
                        {dr.discipline}
                      </td>
                      <td className="px-4" style={{ width: '39%' }}>
                        {dr.event}
                      </td>
                      <td className="px-4" style={{ width: '25%' }}>
                        {dr.athlete}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody className="sky-h7-reg block overflow-y-auto">
                <tr className="flex flex-no-wrap items-center justify-center">
                  <td colSpan={5} className="text-center py-4">
                    No Kiwis competing for the selected criteria
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {!isFullView && dataRecords.length > 0 && (
            <div
              className="absolute bottom-0 w-full h-20"
              style={{ backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255)' }}></div>
          )}
        </div>
      )}
      {isFullView && <ToTop />}
    </div>
  );
};

export { Schedule };
