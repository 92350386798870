import { Loader } from '@sky-tv-group/components';
import React, { useEffect, useState } from 'react';
import { ScheduleHeader } from '../components/scheduler/header';
import { Schedule } from '../components/scheduler/schedule';
import { OLYMPIC_HOME_URL } from '../config';
import { useOlympicSchedule } from '../hooks/useOlympicSchedule';
import { olympicService } from '../services';
import { useStore } from '../store';
const Home = () => {
  const { getScheduleData } = useOlympicSchedule(olympicService);
  const { setOlympicDataRecord, setFilterOptions, fetchChannels } = useStore(s => ({
    setOlympicDataRecord: s.setOlympicDataRecord,
    setFilterOptions: s.setFilterOptions,
    fetchChannels: s.fetchChannels,
  }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchChannels();
    getScheduleData().then(response => {
      if (response) {
        setOlympicDataRecord(response.data);
        setFilterOptions(response.filters);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sky-bg-white container w-full mx-auto" style={{ height: '900px' }}>
      <ScheduleHeader />
      {loading ? (
        <div className="relative h-128">
          <Loader bgOpacity={false} />
        </div>
      ) : (
        <>
          <Schedule isFullView={false} />
          <div className="flex items-center justify-center">
            <a className="sky-button sky-button--primary-light" href={OLYMPIC_HOME_URL} target="_parent">
              View full table
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export { Home };
