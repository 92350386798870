import { FullView } from './screens/full';
import { Home } from './screens/home';
import { MedalHome } from './screens/medal-home';
import { MedalFullView } from './screens/medal-full';

export let routes = [
  {
    path: '/',
    title: 'Olympic Kiwi Schedule',
    component: FullView,
    exact: true,
  },
  {
    path: '/home',
    title: 'Olympic Kiwi Schedule',
    component: Home,
    exact: true,
  },
  {
    path: '/medals',
    title: 'Olympic Medals Table',
    component: MedalFullView,
    exact: true,
  },
  {
    path: '/medals-home',
    title: 'Olympic Medals Table',
    component: MedalHome,
    exact: true,
  },
];
