import React, { useEffect } from 'react';
import { Footer, Header } from '@sky-tv-group/components';
import { ScheduleHeader } from '../components/scheduler/header';
import { Schedule } from '../components/scheduler/schedule';
import { useStore } from '../store';
import { GoBack } from '../components/goBack';
import { Helmet } from 'react-helmet';

const FullView = () => {
  const navLinks = useStore(s => s.navLinks);
  let load = useStore(s => s.loadLinks);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={window.location.protocol + '//' + window.location.host + window.location.pathname}
        />
      </Helmet>
      <Header links={navLinks} />
      <div style={{ background: 'radial-gradient(86.95% 86.84% at -11.94% -8.91%, #F32836 0%, #00013A 100%)' }}>
        <div className="pb-10 m-auto px-6">
          <div className="w-full container mx-auto">
            <GoBack />
            <div className=" sky-bg-white rounded-lg">
              <ScheduleHeader isFullView={true} />
              <Schedule isFullView={true} />
            </div>
          </div>
        </div>
      </div>
      <Footer links={navLinks} />
    </>
  );
};

export { FullView };
