import create from 'zustand';
import { NavigationLinks, T_EPG_Channel } from '@sky-tv-group/shared';
import { getChannels, navService } from './services';
import { NAVIGATION_URL } from './config';
import { MedalRecord, OlympicDataRecord, OlympicFilters } from './types';
import { isPastDate } from './helper/helper';

interface Store {
  navLinks: NavigationLinks;
  loadLinks: () => void;
  olympicDataRecords: OlympicDataRecord[];
  setOlympicDataRecord: (data: OlympicDataRecord[]) => void;
  filterOptions: OlympicFilters;
  setFilterOptions: (filters: OlympicFilters) => void;
  disciplineFilter: string;
  dateFilter: Date | undefined;
  athleteFilter: string;
  setDisciplineFilter: (value: string) => void;
  setDateFilter: (value: Date) => void;
  setAthleteFilter: (value: string) => void;
  channels: T_EPG_Channel[];
  fetchChannels: () => void;
  medals: MedalRecord[];
  setMedals: (value: MedalRecord[]) => void;
}

let [useStore, api] = create<Store>(() => {
  return {
    navLinks: {
      logoUrl: '/',
      logoSrc: '/',
      TVGuideURL: '/',
      header: [],
      side: [],
      footer: [],
      social: [],
      corporate: [],
    },
    olympicDataRecords: [],
    filterOptions: {
      discipline: [],
      atheltes: [],
      days: [],
    },
    disciplineFilter: '',
    dateFilter: undefined,
    athleteFilter: '',
    channels: [],
    medals: [],
    loadLinks: async () => {
      try {
        const navLnk = await navService.getLinks(NAVIGATION_URL);
        set({ navLinks: navLnk });
      } catch (err) {
        console.error(err);
      }
    },
    setOlympicDataRecord: (data: OlympicDataRecord[]) => {
      const filteredPastEvents = data
        .filter(record => !isPastDate(new Date(record.date)) && record.date !== undefined && record.date !== '')
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      set({ olympicDataRecords: filteredPastEvents });
    },
    setFilterOptions: (filters: OlympicFilters) => {
      set({ filterOptions: filters });
    },
    setDisciplineFilter: (value: string) => {
      set({ disciplineFilter: value });
    },
    setDateFilter: (value: Date) => {
      set({ dateFilter: value });
    },
    setAthleteFilter: (value: string) => {
      set({ athleteFilter: value });
    },
    fetchChannels: () => {
      const update = async () => {
        let channelsResponse = await getChannels();
        set({ channels: channelsResponse });
      };
      update();
    },
    setMedals: (value: MedalRecord[]) => {
      set({ medals: value });
    },
  };
});

let set = api.setState;
export { useStore, set };
