import React from 'react';
import { useStore } from '../../store';
import { MedalRecord } from '../../types';
import { ToTop } from '../toTop';

interface MedalTableProps {
  isFullView?: boolean;
}

const MedalTable = ({ isFullView }: MedalTableProps) => {
  const { medals } = useStore(s => ({
    medals: s.medals,
  }));

  let medalArr: MedalRecord[] = [];
  let nzMedal: MedalRecord | undefined;
  medalArr = isFullView ? medals : medals.slice(0, 10);

  if (medalArr && !medalArr.find(medal => medal.code === 'NZL')) {
    nzMedal = medals.find(medal => medal.code === 'NZL');
  }

  if (medals.length < 1) {
    return <div className="flex items-center justify-center h-40">No records found...</div>;
  }

  return (
    <div className="relative flex item-center justify-center md:px-4 pb-4">
      <table className="w-full px-4 md:px-0 table-fixed mx-auto">
        <thead className="w-full block">
          <tr className="text-center w-full flex flex-no-wrap items-center justify-center">
            <th style={{ width: '20%' }}>#</th>
            <th className="text-left" style={{ width: '30%' }}>
              Country
            </th>
            <th className="text-left" style={{ width: '10%' }}>
              <div className="rounded-full h-4 w-4" style={{ backgroundColor: '#F4C563' }}></div>
            </th>
            <th className="text-left" style={{ width: '10%' }}>
              <div className="rounded-full h-4 w-4" style={{ backgroundColor: '#C5C6C6' }}></div>
            </th>
            <th className="text-left" style={{ width: '10%' }}>
              <div className="rounded-full h-4 w-4" style={{ backgroundColor: '#D9A99B' }}></div>
            </th>
            <th style={{ width: '20%' }}>Total</th>
          </tr>
        </thead>
        <tbody className="sky-h7-bold block">
          {medalArr.map(mr => {
            return (
              <tr
                key={mr.code}
                className={`text-center flex w-full flex-no-wrap items-center justify-center py-2 ${
                  mr.code === 'NZL' ? 'sky-bg-gray-lightest rounded-lg' : ''
                } `}>
                <td style={{ width: '20%' }}>{mr.pos}</td>
                <td className="text-left" style={{ width: '30%' }}>
                  {mr.name}
                </td>
                <td className="text-left" style={{ width: '10%' }}>
                  {mr.g}
                </td>
                <td className="text-left" style={{ width: '10%' }}>
                  {mr.s}
                </td>
                <td className="text-left" style={{ width: '10%' }}>
                  {mr.b}
                </td>
                <td className="" style={{ width: '20%' }}>
                  {mr.t}
                </td>
              </tr>
            );
          })}
          {nzMedal && (
            <tr className="text-center flex w-full flex-no-wrap items-center justify-center border-t border-solid border-gray-border py-2">
              <td className="" style={{ width: '20%' }}>
                {nzMedal.pos}
              </td>
              <td className="text-left" style={{ width: '30%' }}>
                {nzMedal.name}
              </td>
              <td className="text-left" style={{ width: '10%' }}>
                {nzMedal.g}
              </td>
              <td className="text-left" style={{ width: '10%' }}>
                {nzMedal.s}
              </td>
              <td className="text-left" style={{ width: '10%' }}>
                {nzMedal.b}
              </td>
              <td className="" style={{ width: '20%' }}>
                {nzMedal.t}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isFullView && <ToTop />}
    </div>
  );
};

export { MedalTable };
