import { Footer, Header, Loader } from '@sky-tv-group/components';
import React, { useEffect, useState } from 'react';
import { MedalTable } from '../components/medal-table/medal-table';
import { MedalHeader } from '../components/medal-table/header';
import { getMedals } from '../services';
import { useStore } from '../store';
import { GoBack } from '../components/goBack';
import { Helmet } from 'react-helmet';

const MedalFullView = () => {
  const navLinks = useStore(s => s.navLinks);
  let load = useStore(s => s.loadLinks);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setMedals } = useStore(s => ({
    setMedals: s.setMedals,
  }));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchIt = async () => {
      let medalsResponse = await getMedals();
      setMedals(medalsResponse);
      setLoading(false);
    };
    fetchIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className="relative h-128">
        <Loader bgOpacity={false} />
      </div>
    );

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={window.location.protocol + '//' + window.location.host + window.location.pathname}
        />
      </Helmet>
      <Header links={navLinks} />
      <div
        className="pb-10"
        style={{ background: 'radial-gradient(86.95% 86.84% at -11.94% -8.91%, #F32836 0%, #00013A 100%)' }}>
        <div className="pb-10 m-auto px-6 md:container">
          <GoBack />
          <div className="w-full md:px-0 sky-bg-white rounded-lg">
            <MedalHeader />
            <MedalTable isFullView={true} />
          </div>
        </div>
      </div>
      <Footer links={navLinks} />
    </>
  );
};

export { MedalFullView };
