import React from 'react';
import { Router } from 'react-router-dom';
import { App } from './App';
import history from './history';

export function Root() {
  return (
    <Router history={history}>
      <App />
    </Router>
  );
}
