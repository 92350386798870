import React, { useState } from 'react';
import { isMobile, isBrowser, isSafari } from 'react-device-detect';

const ToTop = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const checkScrollTop = () =>
    setShowBackToTop(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20);

  window.addEventListener('scroll', checkScrollTop);

  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  let scrollClass = !isBrowser || (isMobile && isSafari) ? 'sky-bg-gray-light mr-4' : 'sky-bg-white -mr-14';

  return (
    <div className="absolute w-full h-full">
      <div className="sticky w-full h-full">
        <button
          onClick={scrollTop}
          className={`float-right rounded-full w-16 h-16 md:w-10 md:h-10 flex flex-row items-center top-11/12 justify-center sticky text-white ${
            showBackToTop ? 'block' : 'hidden'
          } ${scrollClass}`}>
          <img src="/images/Icons/chevron.svg" alt="Go to top" className="w-4" />
        </button>
      </div>
    </div>
  );
};

export { ToTop };
